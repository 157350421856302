const ENVIRONMENTS = {
    DEVELOPMENT: 'dev',
    LOCAL: 'local',
    PRODUCTION: 'prod',
};

const GOOGLE_OAUTH_PROVIDER_ID = 'google.com';

const SNACKBAR_VARIANTS = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};

const VENDORS = {
    CATALIST: 'CATALIST',
    TARGETSMART: 'TARGETSMART',
    NGPVAN: 'NGPVAN',
    PAD: 'PAD',
};

const VENDOR_NAMES = {
    [VENDORS.CATALIST]: 'Catalist',
    [VENDORS.TARGETSMART]: 'TargetSmart',
    [VENDORS.NGPVAN]: 'NGPVan',
    [VENDORS.PAD]: 'PAD',
};

const VENDOR_SLUGS = {
    [VENDORS.CATALIST]: 'catalist',
    [VENDORS.TARGETSMART]: 'targetsmart',
};

const GCP_WORKFLOW_IDS = {
    VOTERFILE_MATCHING: 'voterfile-matching',
    NGPVAN_RETL: 'ngpvan-bulk-import',
};

/** https://cloud.google.com/workflows/docs/reference/executions/rest/v1/projects.locations.workflows.executions#State */
const GCP_WORKFLOW_EXECUTION_STATUSES = {
    ACTIVE: 'ACTIVE',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    STATE_UNSPECIFIED: 'STATE_UNSPECIFIED',
    QUEUED: 'QUEUED',
    CANCELLED: 'CANCELLED',
    UNAVAILABLE: 'UNAVAILABLE',
};

const GCP_WORFKLOW_EXECUTION_STATUS_LABELS = {
    [GCP_WORKFLOW_EXECUTION_STATUSES.ACTIVE]: 'Active',
    [GCP_WORKFLOW_EXECUTION_STATUSES.SUCCEEDED]: 'Succeeded',
    [GCP_WORKFLOW_EXECUTION_STATUSES.FAILED]: 'Failed',
    [GCP_WORKFLOW_EXECUTION_STATUSES.STATE_UNSPECIFIED]: 'Unknown',
    [GCP_WORKFLOW_EXECUTION_STATUSES.QUEUED]: 'Queued',
    [GCP_WORKFLOW_EXECUTION_STATUSES.CANCELLED]: 'Cancelled',
    [GCP_WORKFLOW_EXECUTION_STATUSES.UNAVAILABLE]: 'Unavailable',
};

const GCP_WORKFLOW_EXECUTION_METADATA_FIELDS = {
    JOB_NAME: 'jobName',
    VENDOR_ID: 'vendorId',
    EXECUTION_ID: 'executionId',
};

const GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS = {
    VENDOR: 'VENDOR',
};

const GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH = 100;

const SYNC_POINT_TYPES = {
    ACTBLUE: 'ACTBLUE',
    ACTION_NETWORK_REDSHIFT: 'ACTION_NETWORK_REDSHIFT',
    BIG_QUERY: 'BIG_QUERY',
    GOOGLE_ANALYTICS_GA4: 'GOOGLE_ANALYTICS_GA4',
    MOBILE_COMMONS: 'MOBILE_COMMONS',
    MOBILIZE_POSTGRES: 'MOBILIZE_POSTGRES',
    FACEBOOK_PAGE: 'FACEBOOK_PAGE',
    NGPVAN_APP: 'NGPVAN_APP',
    SPOKE_POSTGRES: 'SPOKE_POSTGRES',
};

const SYNC_POINT_VARIANTS = {
    SOURCE: 'SOURCE',
    DESTINATION: 'DESTINATION',
};

const SYNC_POINT_ROUTE_PARAMS = {
    [SYNC_POINT_TYPES.ACTBLUE]: 'actblue',
    [SYNC_POINT_TYPES.ACTION_NETWORK_REDSHIFT]: 'action-network',
    [SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4]: 'google-analytics',
    [SYNC_POINT_TYPES.MOBILE_COMMONS]: 'mobile-commons',
    [SYNC_POINT_TYPES.MOBILIZE_POSTGRES]: 'mobilize',
    [SYNC_POINT_TYPES.SPOKE_POSTGRES]: 'spoke',
};

const SYNC_POINT_STATUSES = {
    BUILDING: 'BUILDING', // created in DB but not yet ready for processing
    CONNECTED: 'CONNECTED', // processing complete & succeesful
    ERROR: 'ERROR', // processing failed
    PROCESSING: 'PROCESSING', // processing in progress, e.g. new source being created in airbyte
};

const SYNC_SCHEDULES_BY_SOURCE_TYPE = {
    [SYNC_POINT_TYPES.ACTBLUE]: '00 7 * * *', // every day at 3AM UTC,
    [SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4]: '30 7 * * *', // every day at 3:30AM UTC
    [SYNC_POINT_TYPES.MOBILE_COMMONS]: '00 8 * * *', // every day at 4AM UTC
    [SYNC_POINT_TYPES.MOBILIZE_POSTGRES]: '30 8 * * *', // every day at 4:30AM UTC
    [SYNC_POINT_TYPES.SPOKE_POSTGRES]: '00 9 * * *', // every day at 5AM UTC
};

const SYNC_POINT_ICON_IMAGE_FILES = {
    [SYNC_POINT_TYPES.ACTBLUE]: 'actblue_icon.png',
    [SYNC_POINT_TYPES.ACTION_NETWORK_REDSHIFT]: 'action_network_icon.png',
    [SYNC_POINT_TYPES.BIG_QUERY]: 'big_query_icon.png',
    [SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4]: 'google_analytics_icon.png',
    [SYNC_POINT_TYPES.MOBILE_COMMONS]: 'mobile_commons_icon.png',
    [SYNC_POINT_TYPES.MOBILIZE_POSTGRES]: 'mobilize_icon.png',
    [SYNC_POINT_TYPES.SPOKE_POSTGRES]: 'spoke_icon.png',
};

const SYNC_STATUSES = {
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    ERROR: 'ERROR',
    INACTIVE: 'INACTIVE',
    QUEUED: 'QUEUED',
    PROCESSING: 'PROCESSING',
};

const SYNC_STATUS_LABELS = {
    [SYNC_STATUSES.ACTIVE]: 'Active',
    [SYNC_STATUSES.DRAFT]: 'Draft',
    [SYNC_STATUSES.ERROR]: 'Error',
    [SYNC_STATUSES.INACTIVE]: 'Inactive',
    [SYNC_STATUSES.QUEUED]: 'Queued',
    [SYNC_STATUSES.PROCESSING]: 'Processing',
};

const SYNC_FREQUENCIES = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

const SYNC_FREQUENCY_LABELS = {
    [SYNC_FREQUENCIES.DAILY]: 'Daily',
    [SYNC_FREQUENCIES.WEEKLY]: 'Weekly',
    [SYNC_FREQUENCIES.MONTHLY]: 'Monthly',
};

const SYNC_RUN_STATUSES = {
    SUCCEEDED: 'success',
    FAILED: 'failed',
};

const SYNC_RUN_STATUS_LABELS = {
    [SYNC_RUN_STATUSES.SUCCEEDED]: 'Succeeded',
    [SYNC_RUN_STATUSES.FAILED]: 'Failed',
};

const DEFAULT_SOURCE_TYPES = [
    SYNC_POINT_TYPES.ACTBLUE,
    SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4,
    SYNC_POINT_TYPES.MOBILE_COMMONS,
    SYNC_POINT_TYPES.MOBILIZE_POSTGRES,
    SYNC_POINT_TYPES.SPOKE_POSTGRES,
];

const PROJECT_LIST_QUERY_PARAMS = {
    PAGE_SIZE: 50,
    FILTER_STRING: 'labels.type:partner labels.location:us state:ACTIVE',
};

const USER_ROLES = {
    ADMIN: 'ADMIN',
    EDITOR: 'EDITOR',
    CONTRIBUTOR: 'CONTRIBUTOR',
    VIEWER: 'VIEWER',
};

const USER_ROLE_LABELS = {
    [USER_ROLES.ADMIN]: 'Admin',
    [USER_ROLES.EDITOR]: 'Editor',
    [USER_ROLES.CONTRIBUTOR]: 'Contributor',
    [USER_ROLES.VIEWER]: 'Viewer',
};

/**
 * Flags with explicit values in the datastore
 */
const DEFINED_PROJECT_FEATURE_FLAGS = {
    IS_CATALIST_MATCHING_ENABLED: 'isCatalistMatchingEnabled',
    IS_TARGETSMART_MATCHING_ENABLED: 'isTargetSmartMatchingEnabled',
    IS_VAN_RETL_ENABLED: 'isVANRETLEnabled',
    IS_HOPPER_SYNCS_PAGE_ENABLED: 'isHopperSyncsPageEnabled',
    IS_HOPPER_SOURCES_PAGE_ENABLED: 'isHopperSourcesPageEnabled',
    IS_MANAGED_SYNCS_PAGE_ENABLED: 'isManagedSyncsPageEnabled',
};

/**
 * Flags derived from defined flags, not stored in the datastore
 */
const DERIVED_PROJECT_FEATURE_FLAGS = {
    IS_MATCHING_ENABLED: 'isMatchingEnabled',
    ARE_BOTH_MATCHING_PROVIDERS_ENABLED: 'areBothMatchingProvidersEnabled',
};

const PROJECT_FEATURE_FLAGS = {
    ...DEFINED_PROJECT_FEATURE_FLAGS,
    ...DERIVED_PROJECT_FEATURE_FLAGS,
};

/**
 * Super users will have these flags enabled by default, regardless of current project role
 */
const DEFAULT_FEATURE_FLAGS_FOR_SUPER_USERS = {
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_VAN_RETL_ENABLED]: true,
};

const FIRESTORE_COLLECTIONS = {
    PROJECTS: 'projects',
    USERS: 'users',
    CREDENTIALS: 'credentials',
    SYNCS: 'syncs',
    SOURCES: 'sources',
    DESTINATIONS: 'destinations',
    UNIVERSAL_DESTINATIONS: 'universalDestinations',
    SYNC_POINT_CONFIGURATIONS: 'syncPointConfigurations',
    PRIVATE: 'private',
    MANAGED_SYNCS: 'syncs',
};

const SYNC_PLATFORMS = {
    AIRBYTE: 'AIRBYTE',
};

const SYNC_FIELDS = {
    LAST_EXECUTION_DATE: 'lastExecutionDate',
    PARTNER_ID: 'partnerName',
    PROJECT_ID: 'projectID',
    VENDOR_NAME: 'vendorName',
    SHARED_TO: 'sharedTo',
};

const CATALIST_MATCHING_CONFIG = {
    columnMatchingHelperText:
        "At least one of 'Date of Birth' or full home address is also required. Home address includes 'Home Address 1', 'Home Address 2', 'Home City' and 'Home Zip Code'",
    visibleFields: [
        {
            label: 'First Name',
            header: 'FIRST_NAME',
            isRequired: true,
        },
        {
            label: 'Middle Name',
            header: 'MIDDLE_NAME',
        },
        {
            label: 'Last Name',
            header: 'LAST_NAME',
            isRequired: true,
        },
        {
            label: 'Name Suffix',
            header: 'NAME_SUFFIX',
        },
        {
            label: 'Date of Birth',
            header: 'BIRTHDATE',
        },
        {
            label: 'Home Address Line 1',
            header: 'HOME_ADDRESS_LINE_1',
        },
        {
            label: 'Home Address Line 2',
            header: 'HOME_ADDRESS_LINE_2',
        },
        {
            label: 'Home City',
            header: 'HOME_CITY',
        },
        {
            label: 'Home State or Territory',
            header: 'HOME_STATE_OR_TERRITORY',
            isRequired: true,
        },
        {
            label: 'Home Zip Code',
            header: 'HOME_ZIP_POSTAL_CODE',
        },
        {
            label: 'Home Zip 4',
            header: 'HOME_ZIP4',
        },
        {
            label: 'Home County FIPS',
            header: 'HOME_COUNTY_FIPS',
        },
        {
            label: 'Mailing Address Line 1',
            header: 'MAILING_ADDRESS_LINE_1',
        },
        {
            label: 'Mailing Address Line 2',
            header: 'MAILING_ADDRESS_LINE_2',
        },
        {
            label: 'Mailing City',
            header: 'MAILING_CITY',
        },
        {
            label: 'Mailing State or Territory',
            header: 'MAILING_STATE_OR_TERRITORY',
        },
        {
            label: 'Mailing Zip Code',
            header: 'MAILING_ZIP_POSTAL_CODE',
        },
        {
            label: 'Mailing Zip 4',
            header: 'MAILING_ZIP4',
        },
        {
            label: 'Mailing County FIPS',
            header: 'MAILING_COUNTY_FIPS',
        },
        {
            label: 'Gender',
            header: 'GENDER',
        },
        {
            label: 'Email address',
            header: 'EMAIL',
        },
        {
            label: 'Phone number',
            header: 'PHONE',
        },
        {
            label: 'State Voterfile ID',
            header: 'STATE_VOTER_FILE_ID',
        },
        {
            label: 'County Voterfile ID',
            header: 'COUNTY_VOTER_FILE_ID',
        },
        {
            label: 'Registration Date',
            header: 'REGISTRATION_DATE',
        },
    ],
};

const TARGETSMART_MATCHING_CONFIG = {
    userFriendlyName: 'TargetSmart',
    visibleFields: [
        {
            label: 'First Name',
            header: 'first_name',
        },
        {
            label: 'Middle Name',
            header: 'middle_name',
        },
        {
            label: 'Last Name',
            header: 'last_name',
        },
        {
            label: 'Full Name',
            header: 'full_name',
        },
        {
            label: 'First Name Combined',
            header: 'first_name_combined',
        },
        {
            label: 'Name Suffix',
            header: 'name_suffix',
        },
        {
            label: 'Address Line 1',
            header: 'address1',
        },
        {
            label: 'Address Line 2',
            header: 'address2',
        },
        {
            label: 'City',
            header: 'city',
        },
        {
            label: 'State',
            header: 'state',
        },
        {
            label: 'Zip Code',
            header: 'zip',
        },
        {
            label: 'Gender',
            header: 'gender',
        },
        {
            label: 'Date of Birth',
            header: 'dob',
        },
        {
            label: 'Phone Number',
            header: 'phone',
        },
        {
            label: 'Email Address',
            header: 'email',
        },
        {
            label: 'Voterbase ID',
            header: 'voterbase_id',
        },
        {
            label: 'SmartVAN VAN ID',
            header: 'smartvan_id',
        },
        {
            label: 'State Voter ID',
            header: 'voter_id',
        },
        {
            label: 'ExactTrack Person ID',
            header: 'exact_track',
        },
        {
            label: 'Latitude',
            header: 'latitude',
        },
        {
            label: 'Longitude',
            header: 'longitude',
        },
    ],
};

const VAN_RETL_CONFIG = {
    fieldMatchingHelperText: 'You must match VAN ID if you intend to update existing contacts.',
    invalidMatchedFieldsMessage: 'Please match at least one field.',
    fields: [
        {
            name: 'VanID',
            description: 'VAN ID (required if updating)',
        },
        {
            name: 'AddressLine1',
            description: 'Address Line 1',
        },
        {
            name: 'AddressLine2',
            description: 'Address Line 2',
        },
        {
            name: 'AddressLine3',
            description: 'Address Line 3',
        },
        {
            name: 'CellPhone',
            description: 'Cell Phone',
        },
        {
            name: 'City',
            description: 'City',
        },
        {
            name: 'CountryCode',
            description: 'Country',
        },
        {
            name: 'DOB',
            description: 'Date of Birth',
        },
        {
            name: 'Email',
            description: 'Email',
        },
        {
            name: 'Phone',
            description: 'Home Phone',
        },
        {
            name: 'EmployerName',
            description: 'Employer Name',
        },
        {
            name: 'AdditionalEnvelopeName',
            description: 'Envelope Name - Additional',
        },
        {
            name: 'FirstName',
            description: 'First Name',
        },
        {
            name: 'MiddleName',
            description: 'Middle Name',
        },
        {
            name: 'LastName',
            description: 'Last Name',
        },
        {
            name: 'OccupationName',
            description: 'Occupation Name',
        },
        {
            name: 'StateOrProvince',
            description: 'State Or Province',
        },
        {
            name: 'ZipOrPostal',
            description: 'Zip Or Postal Code',
        },
    ],
};

export {
    CATALIST_MATCHING_CONFIG,
    DEFAULT_FEATURE_FLAGS_FOR_SUPER_USERS,
    DEFAULT_SOURCE_TYPES,
    DEFINED_PROJECT_FEATURE_FLAGS,
    DERIVED_PROJECT_FEATURE_FLAGS,
    ENVIRONMENTS,
    FIRESTORE_COLLECTIONS,
    GCP_WORFKLOW_EXECUTION_STATUS_LABELS,
    GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH,
    GCP_WORKFLOW_EXECUTION_METADATA_FIELDS,
    GCP_WORKFLOW_EXECUTION_STATUSES,
    GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS,
    GCP_WORKFLOW_IDS,
    GOOGLE_OAUTH_PROVIDER_ID,
    PROJECT_FEATURE_FLAGS,
    PROJECT_LIST_QUERY_PARAMS,
    SNACKBAR_VARIANTS,
    SYNC_FIELDS,
    SYNC_FREQUENCIES,
    SYNC_FREQUENCY_LABELS,
    SYNC_PLATFORMS,
    SYNC_POINT_ICON_IMAGE_FILES,
    SYNC_POINT_ROUTE_PARAMS,
    SYNC_POINT_STATUSES,
    SYNC_POINT_TYPES,
    SYNC_POINT_VARIANTS,
    SYNC_RUN_STATUS_LABELS,
    SYNC_RUN_STATUSES,
    SYNC_SCHEDULES_BY_SOURCE_TYPE,
    SYNC_STATUS_LABELS,
    SYNC_STATUSES,
    TARGETSMART_MATCHING_CONFIG,
    USER_ROLE_LABELS,
    USER_ROLES,
    VAN_RETL_CONFIG,
    VENDOR_NAMES,
    VENDOR_SLUGS,
    VENDORS,
};
